@import '@/styles/breakpoints.module';

.btnWrapper {
  text-align: center;
  a {
    width: 100%;
    @include tablet {
      width: unset;
    }
  }
}
