@import '@/styles/breakpoints.module';

.articleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--vstack-spacing-sm);

  @include tablet {
    gap: var(--vstack-spacing-md);
  }

  @include desktop {
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 40px;

    &:nth-of-type(even) {
      flex-direction: row;
    }
  }
}

.imageWrapper {
  width: 100%;
  max-width: 458px;
  flex-shrink: 0;
  border-radius: var(--image-wrapper-border-radius-s);
  box-shadow: var(--image-wrapper-shadow-s);
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @include tablet {
    border-radius: var(--image-wrapper-border-radius-m);
    box-shadow: var(--image-wrapper-shadow-m);
  }
}

/* removes shadow from top */
.altShadow {
  box-shadow: 1px 20px 36px -20px var(--color-shadow-base);
  @include tablet {
    box-shadow: 2px 50px 30px -30px var(--color-shadow-base);
  }
}

.body {
  display: grid;
  gap: 24px;

  @include tablet {
    gap: 32px;
  }

  @include desktop {
    max-width: 600px;
  }
}

.list {
  display: grid;
  gap: 12px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--palette-gray-150);
}

.listItem {
  display: flex;
  height: fit-content;

  .text {
    color: var(--color-text-primary);
    font-weight: var(--palette-font-weight-medium);
  }
}

.iconWrapper {
  color: var(--palette-primary-700);
  height: fit-content;
  margin-inline-end: 12px;
}

.ctaWrapper {
  text-align: center;
  display: grid;
  gap: 24px;

  a {
    width: 100%;
    @include tablet {
      width: auto;
    }
  }

  @include tablet {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  @include desktop {
    gap: unset;
    row-gap: 12px;
  }
}

.secondaryCta:not(:first-child) {
  @include desktop {
    margin-inline-start: 24px;
  }
}
