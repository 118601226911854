@import '@/styles/breakpoints.module';

.expertBannerHeading {
  grid-area: heading;
  display: flex;
  flex-direction: column;

  gap: 12px;

  @include tablet {
    gap: 16px;
  }
}

.expertBannerTitle {
  color: var(--palette-white);
}

.expertBannerSubheading {
  color: var(--palette-gray-200);
}
