@import '@/styles/breakpoints.module';

.wrapper {
  background: white;
  border-radius: var(--border-radius-nav-product-label);
  background: linear-gradient(90deg, var(--palette-gray-50) 0%, #dbdbdb 100%);

  .bottomWrapper {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
    padding: 0 16px 24px;

    @include tablet {
      margin-top: 32px;
      padding: 0 32px 32px;
    }

    @include desktop {
      gap: 60px;
      grid-template-columns: 60% 1fr;
      margin-top: 40px;
      padding: 0 60px 60px;
    }

    .desc {
      display: none;

      @include desktop {
        display: block;
      }

      img {
        width: 100%;
        height: auto;
        max-height: 320px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .mobileBox {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
        margin-bottom: 16px;


        @include tablet {
          grid-template-columns: 65% 1fr;
          margin-bottom: var(--vstack-spacing-sm);
        }

        @include desktop {
          display: block;
        }

        .mobile {
          display: flex;
          justify-content: center;
          align-items: center;

          @include desktop {
            display: none;
          }

          img {
            display: block;
            width: 137px;
            height: auto;

            @include tablet {
              width: 202px;
              height: 187px;
              object-fit: contain;
            }
          }
        }
      }

      .contentTitle {
        color: var(--color-banner-bg);
        margin-bottom: 8px;

        @include tablet {
          margin-bottom: 16px;
        }
      }

      .contentSubtitle {
        color: var(--palette-gray-600);
        margin-bottom: var(--vstack-spacing-sm);
      }

      .timer {
        .timerTitle {
          display: flex;
          align-items: center;
          gap: 4px;
          margin-bottom: 12px;
          justify-content: center;

          p {
            color: var(--palette-gray-600);
          }

          .timerIcon {
            flex-shrink: 0;
            width: 24px;
            height: 24px;
            color: var(--color-trend-negative);
          }

          @include tablet {
            justify-content: initial;
          }
        }

        .timerContent {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 16px;

          @include tablet {
            gap: var(--vstack-spacing-sm);
            flex-direction: row;
            align-items: flex-start;
          }

          @include desktop {
            gap: 32px;
          }

          .timerItems {
            display: flex;
            justify-content: space-between;
            max-width: 270px;
            width: 100%;

            .spaces {
              height: 56px;

              .wrapperSpaces {
                margin: auto 8px;
                display: flex;
                flex-direction: column;
                gap: 4px;
                align-items: center;
                justify-content: center;
                height: 100%;

                .block {
                  width: 4px;
                  height: 4px;
                  background: var(--color-banner-bg);
                }
              }

              @include tablet {
                height: 76px;
              }
            }

            .timerItem {
              p {
                color: var(--palette-gray-400);
                text-align: center;
                margin-top: 4px;
              }

              .timerItemWrapper {
                border-radius: var(--border-radius-sections-group);
                background: var(--color-text-primary);
                padding: 12px 16px;
                color: var(--color-banner-bg);
              }
            }

            @include tablet {
              max-width: 305px;
            }
          }
        }
      }
    }
  }

  .topWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 12px;
    padding: 16px;
    border-bottom: 1px solid var(--palette-gray-150);

    .wrapperTooltip {
      display: flex;
      align-items: center;
      gap: 4px;

      P {
        color: var(--color-banner-bg);
      }
    }

    .tab {
      border-radius: var(--palette-border-radius-xxxl);
      background: var(--color-text-primary);
      padding: 8px 16px;
      color: var(--color-btn-primary-bg);
      text-align: center;
    }

    @include tablet {
      flex-direction: row;
      padding: 32px;
      align-items: center;
    }

    @include desktop {
      padding: 40px 60px;
    }
  }
}

.cta {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  .btnWrapper {
    width: 100%;
    flex-shrink: 0;

    a {
      width: 100%;
    }

    @include tablet {
      width: initial;
      margin-top: 6px;
    }
  }

  @include tablet {
    gap: var(--vstack-spacing-sm);
    flex-direction: row;
    align-items: flex-start;
  }

  @include desktop {
    gap: 32px;
  }
}

.legalDocument {
  color: var(--palette-primary-700) !important;
  margin-bottom: 24px;
}
