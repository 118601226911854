@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.section {
  padding: 40px 16px;
  max-width: 1440px;
  margin: 0 auto;
  @include tablet {
    padding: 80px 40px;
  }
  @include desktop {
    padding: 100px 40px;
  }
  @include large-desk {
    padding: 100px 150px;
  }
}

.layout {
  display: grid;
  gap: var(--vstack-spacing-sm);
  @include tablet {
    gap: var(--vstack-spacing-md);
  }
  @include desktop {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
}

.sectionTitle {
  span {
    color: var(--color-accent-heading-fragment);
  }
}

.brResponsive {
  display: none;
  @include desktop {
    display: inline;
  }
  @include large-desk {
    display: none;
  }
}

.imgWrapper {
  @include desktop {
    display: block;
    margin: 0 auto;
    width: fit-content;
  }
}

.stepsCard {
  border-radius: 12px;
  background-color: var(--palette-white);
  padding: 16px;
  @include tablet {
    padding: 40px;
  }
  @include desktop {
    min-width: 459px;
    padding: 60px 12px;
  }

  .stepsCardInner {
    @include desktop {
      max-width: 435px;
      margin: 0 auto;
    }
  }
}

.stepWrapper {
  display: flex;
  padding-bottom: 24px;
  margin-bottom: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--palette-gray-100);
  }

  @include tablet {
    padding-bottom: 32px;
    margin-bottom: 32px;
  }
}

.square {
  padding: var(--padding-card);
  border-radius: var(--border-radius-card);
  background-color: var(--palette-primary-700);
  color: var(--palette-white);
  width: 40px;
  height: 40px;
  text-align: center;
  margin-inline-end: 12px;
  min-width: 40px;
  @include buttonS_;
  @include tablet {
    margin-inline-end: 16px;
    padding: 2px;
    @include subheadingS_;
    line-height: 35px;
  }
  @include desktop {
    margin-bottom: 0;
  }
}

.stepTextWrapper {
  display: flex;
  align-items: center;

  .stepText {
    color: var(--palette-gray-600);
  }
}

.firstBlock {
  @include desktop {
    order: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.contentBlock {
  span {
    color: var(--color-heading);
  }

  @include desktop {
    width: 100%;
    max-width: 413px;
  }
  @include large-desk {
    width: 100%;
    max-width: 458px;
  }
}

.secondBlock {
  position: relative;
  @include desktop {
    order: 1;
  }

  .arrowImgWrapper {
    display: none;
    @include tablet {
      display: block;
      position: absolute;
      top: -32px;
      inset-inline-end: 0;
    }
    @include desktop {
      top: -15px;
      inset-inline-end: -40px;
    }
  }

  .legalText {
    display: none;

    @include desktop {
      color: var(--color-fees-card-condition-value);
      display: block;
      text-align: center;
      margin-top: 16px;
    }
  }
}

.btnWrapper {
  margin-top: 16px;
  @include tablet {
    margin-top: 32px;
  }
  @include desktop {
    text-align: center;
  }

  a {
    width: 100%;
    @include tablet {
      width: unset;
    }
  }
}
