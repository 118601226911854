@import '@/styles/breakpoints.module';

.card {
  border-radius: var(--palette-border-radius-m);

  padding: var(--vstack-spacing-sm);

  @include tablet {
    padding: 40px 32px;
  }

  @include desktop {
    padding: 40px 60px;
  }
}

.checkersImageArticle {
  > div:first-child {
    z-index: 2;
  }

  h3 {
    font-size: 20px;

    @include tablet {
      font-size: 28px;
      line-height: 36px;
    }

    @include desktop {
      font-size: 40px;
      line-height: 52px;
    }
  }

  svg {
    color: var(--palette-white);
  }

  div:has(img) {
    box-shadow: none;
    background: none !important;
    width: 100%;
    display: flex;
    align-items: center;

    overflow: visible;
    max-width: 100;

    @include tablet {
      max-width: 25%;
      min-height: 280px;
      min-width: unset;
    };

    @include desktop {
      min-width: 340px;
      min-height: 400px;
    }
  }

  div:has(img) img {
    transform: scale(1.2) translateX(0);

    @include tablet {
      width: none;
      transform: scale(2) translateX(-10px);
    }

    @include desktop {
      transform: scale(2) translateX(-20px);
    }
  }

  flex-direction: column-reverse !important;

  @include tablet {
    flex-direction: row-reverse !important;
  }
}

.list {
  border-top: unset !important;
  padding-top: unset !important;


  gap: 4px !important;

  @include tablet {
    gap: 8px !important;
  }

  @include desktop {
    gap: 12px !important;
  }

  p {
    @include tablet {
      font-size: 14px;
    }
  }
}

.secondaryCta {
  @include desktop {
    margin-inline-start: 16px;
  }

  &:hover {
    background-color: unset !important;
    border-color: var(--palette-white) !important;
  }
}

.legalDocument {
  @include desktop {
    margin-inline-start: var(--vstack-spacing-sm);
  }
}
