@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.cards {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr;
  justify-items: center;

  @include tablet {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.cardDescription {
  @include typography.bodyS_;
  @include tablet {
    @include typography.subheadingM_;
  }
}

.card {
  img {
    width: 100%;
    height: calc(100% - 88px - 24px * 2);
    object-fit: cover;
    object-position: bottom;
  }

  max-width: 360px !important;
  max-height: 320px !important;

  width: 100% !important;

  @include tablet {
    height: 320px !important;
  }

  h3 {
    font-size: 48px;
    line-height: 60px;


    &:not(:hover) {
      color: var(--color-accent-heading-fragment);
    }

    @include tablet {
      font-size: 28px;
      line-height: 36px;
    }

    @include desktop {
      font-size: 48px;
      line-height: 60px;
    }
  }

  p, p > span {
    font-size: 18px;
    line-height: 28px;

    @include tablet {
      line-height: 20px;
      font-size: 14px;
    }

    @include desktop {
      font-size: 18px;
      line-height: 28px;
    }
  }

  p {
    margin-top: 4px;
  }

  div:has(> h3) {
    padding: 24px 24px 0;
  }

  div:has(> img) {
    margin-top: 2px !important;

    @include tablet {
      margin-top: 14px !important;
    }

    @include desktop {
      margin-top: 2px !important;
    }
  }

  > div {
    border-radius: var(--palette-border-radius-m);
  }
}

.tag {
  background-color: var(--palette-primary-700);
  border-radius: var(--palette-border-radius-xm);
  color: var(--palette-white) !important;
  font-weight: var(--palette-font-weight-medium);
  width: fit-content;
  padding: 2px 10px;
  margin-bottom: 8px;
}

.cryptoTag {
  background-color: var(--palette-green-700);
}
