@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.progressContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: var(--palette-white);
  border-radius: var(--palette-border-radius-m);

  padding: 16px;

  @include tablet {
    padding: 32px;
  }

  @include desktop {
    padding: 60px;
  }
}

.stepsNavigation {
  display: flex;
  justify-content: space-between;

  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }
}

.stepNavigationItem {
  padding: 16px;
  display: flex;
  gap: 12px;
  align-items: center;

  @include tablet {
    padding: 12px 8px;
  }

  @include desktop {
    padding: 16px;
  }
}

.stepIconWrapper {
  background-color: var(--palette-primary-75);
  border-radius: var(--palette-border-radius-m);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  svg {
    width: 16px;
    height: 16px;

    color: var(--palette-primary-700);
  }

  @include desktop {
    height: 48px;
    width: 48px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.stepHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  color: var(--palette-primary-700);
  font-weight: var(--palette-font-weight-medium);

  @include typography.bodyXS_;

  @include desktop {
    @include typography.bodyM_;
  }
}

.disabled {
  .stepIconWrapper {
    background-color: var(--palette-gray-70);

    svg {
      color: var(--palette-gray-300);
    }
  }

  .stepHeading {
    color: var(--palette-gray-300);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;

  border: 1px solid var(--palette-gray-150);
  border-radius: var(--palette-border-radius-m);

  @include desktop {
    gap: 24px;
    padding: 32px;
  }

  .heading {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;

    @include tablet {
      flex-direction: row;
      gap: 0;
    }

    padding: 16px 0;

    @include typography.h4S_;

    @include desktop {
      @include typography.h4L_;
    }
  }

  .pro {
    display: flex;
    padding: 4px 16px;
    align-items: center;
    gap: 8px;
    border-radius: var(--palette-border-radius-xxxl);
    background: linear-gradient(180deg, #ffdc45 0%, #fbbe00 100%);
    margin-inline-start: 12px;
  }
}

.stepInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include tablet {
    font-size: 18px;
  }
}

.cards {
  display: flex;
  flex-direction: column;

  gap: 12px;

  @include tablet {
    flex-direction: row;
    gap: 16px;
  }

  @include desktop {
    gap: 30px;
  }
}

.card {
  display: flex;
  gap: 12px;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: space-between;
  background: var(--palette-gray-70);
  border-radius: var(--palette-border-radius-m);

  @include tablet {
    height: 108px;
  }

  @include desktop {
    height: 220px;
  }

  .cardInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;

    align-self: center;

    padding: 24px 16px;
    padding-inline-end: 0;

    @include desktop {
      padding: 32px 24px;
      padding-inline-end: 0;
    }

    .cardBonusAmount {
      @include typography.h1S_;
      @include tablet {
        @include typography.h2S_;
      }
      @include desktop {
        @include typography.h2L_;
      }
    }

    .cardBonusDescription {
      margin-top: 4px;
      @include typography.bodyS_;
      @include desktop {
        @include typography.subheadingM_;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    max-width: 149px;
    @include desktop {
      width: 20vw;
      max-width: 230px;
    }
  }
}

.ctaWrapper {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @include tablet {
    margin-top: 12px;
  }

  @include desktop {
    margin-top: 24px;
  }
}

.mobile {
  display: block;

  @include tablet {
    display: none;
  }
}

.desktop {
  display: none;

  @include tablet {
    display: flex;
  }
}
