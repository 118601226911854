@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.section.section {
  .wrapperSection {
    display: flex;
    justify-content: space-between;
    gap: var(--vstack-spacing-sm);
    flex-direction: column;

    @include tablet {
      gap: var(--vstack-spacing-md);
    }

    @include desktop {
      flex-direction: row;
      gap: 30px;
    }

    .wrapperContent {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: var(--vstack-spacing-sm);

      .ratingContent {
        display: flex;
        flex-direction: column;

        .wrapperImg {
          svg,
          img {
            width: auto;
            height: auto;
          }
          display: flex;
          justify-content: center;
          @include tablet {
            justify-content: initial;
          }
        }

        .wrapperRatingItem {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: 10px;
          justify-content: center;

          @include tablet {
            margin-top: 8px;
            gap: 12px;
            justify-content: initial;
          }
        }

        .rating {
          color: var(--palette-white);
        }

        .line {
          margin: 16px 0;
          background: var(--palette-gray-500);
          width: 100%;
          height: 1px;
          flex-shrink: 0;

          @include tablet {
            width: 1px;
            height: auto;
            margin: 0 24px;
          }
        }

        @include tablet {
          flex-direction: row;
        }
      }

      @include tablet {
        gap: 24px;
      }

      @include desktop {
        max-width: 460px;
        gap: 32px;
      }
    }
  }

  ol {
    counter-reset: item;

    li {
      position: relative;
      counter-increment: item;

      &:before {
        content: counter(item) '. ';
      }
    }
  }
}

.titleBr {
  display: block;

  @include desktop {
    display: none;
  }
}

.header {
  text-align: center;

  @include tablet {
    text-align: start;
  }
}

.wrapper {
  padding: 40px 0;

  @include desktop {
    padding: 100px 0;
  }
}

.sliderContainer {
  @include tablet {
    padding: 0;
  }
}

.testimonialsLayout {
  display: none;

  @include desktop {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 48px auto 0;
    gap: 8px;
    justify-content: space-between;
    max-height: 1100px;
    overflow: hidden;
  }
}

.columnLayout {
  width: 100%;
  display: grid;
  gap: 8px;
  height: fit-content;

  @include tablet {
    gap: 16px;
  }
  @include desktop {
    max-width: 360px;
    gap: clamp(8px, calc(8px + (30 - 8) * ((100vw - 1170px) / (1220 - 1170))), 30px);
  }

  &:nth-child(2) {
    justify-self: center;
  }

  &:nth-child(3) {
    justify-self: flex-end;
  }
}

.card {
  padding: 16px;
  background-color: var(--palette-gray-600);
  border-radius: var(--palette-border-radius-m);
  height: 100%;
  width: 100%;
  display: grid;
  @include tablet {
    width: 343px;
  }
  @include desktop {
    padding: 24px 16px;
    width: 100%;
    height: fit-content;
  }

  p {
    padding-bottom: 16px;
    color: var(--palette-gray-150);
    @include bodyM_;
    @include desktop {
      @include subheadingM_;
    }
  }

  .btmBlock {
    padding-top: 16px;
    border-top: 1px solid var(--palette-gray-500);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--color-text-primary);
    margin-top: auto;

    .authorSpan {
      font-weight: var(--palette-font-weight-semibold);
      @include subheadingS_;
      @include desktop {
        @include subheadingM_;
      }
    }
  }

  transition: all 300ms ease-in-out;

  @include desktop {
    transition: all 50ms ease-in-out;
    &.accentCard,
    &:hover {
      background-color: var(--palette-white);

      p {
        color: var(--palette-gray-600);
      }

      span {
        color: var(--palette-black);
      }

      .btmBlock {
        border-top: 1px solid var(--palette-gray-150);
      }
    }
  }
}

.testimonialsOverlay {
  display: none;
  @include desktop {
    display: block;
    background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, var(--palette-black) 100%);
    height: 225px;
    width: 100%;
    position: absolute;
    bottom: 0;
    pointer-events: none;
  }
}

.cardsWrapper {
  position: relative;
  margin-top: 32px;
  @include tablet {
    margin-top: 48px;
  }
  @include desktop {
    margin-top: 0;
  }
}

.customPrxsTestimonials {
  cursor: grab;

  .splideSlide {
    width: fit-content;
    height: unset;
    transition-duration: 300ms;

    &.is-active {
      & > div {
        background-color: var(--palette-white);

        p {
          color: var(--palette-gray-600);
        }

        span {
          .ratingAmountWhite {
            color: var(--palette-black);
          }

          color: var(--palette-black);
        }
      }
    }
  }
}
